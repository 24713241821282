<template>
    <o-data-lookup :data-object="dsRisks" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <o-column field="Name" width="350"></o-column>        
        <o-column field="Status" width="120"></o-column>
        <o-column field="OrgUnit" width="300"></o-column>
        <o-column field="Type" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsRisks = getOrCreateDataObject({
    id: 'dsRisks' + crypto.randomUUID(),
    viewName: 'aviw_RiskManager_Uncertainties',
    maxRecords: 25,
    whereClause: "Deleted IS NULL",
    loadRecents: false,
    distinctRows: true,
    fields: [
        {name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Status", type: "string" },
        {name: "Type", type: "string" },
        {name: "OrgUnit", type: "string" },
        {name: "OrgUnitIdPath", type: "string" }
    ]
});
</script>
